
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { FormPage } from '@/mixins'
import { BusinessBranchModel, BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchStatusUpdateDto from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchStatusUpdateDto'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Mixins } from 'vue-property-decorator'
import { Config } from 'tradingmate-modules/src/environment'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader
  }
})

export default class MembershipsExpress extends Mixins(FormPage) {
  private expressSignUps: BusinessBranchModel[] | null = null
  private markingActive = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getExpress()
  }

  getExpress (): void {
    this.loading = true
    Services.API.Admin.Businesses.QueryExpressBranches()
      .then((result) => { this.expressSignUps = result })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.loading = false })
  }

  onSetActive (branchId: string): void {
    const dto: BusinessBranchStatusUpdateDto = { Status: BusinessBranchStatus.Active }
    this.markingActive = true
    Services.API.Admin.Businesses.StatusUpdate(branchId, dto)
      .then((result) => {
        if (!this.expressSignUps) return
        const index = this.expressSignUps.findIndex((x) => x.BusinessBranchId === result.BusinessBranchId)
        this.expressSignUps.splice(index, 1)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.markingActive = false })

    Services.API.Admin.Businesses.ExpressSignupComplete(branchId)
      .then(() => {
        if (!this.expressSignUps) return
        const index = this.expressSignUps.findIndex((x) => x.BusinessBranchId === branchId)
        this.expressSignUps.splice(index, 1)
      })
      .catch((errors) => { this.httpError = errors })
      .finally(() => { this.markingActive = false })
  }

  onViewProfile (slug: string): void {
    const frontendBaseUrl = Config.WebsiteUrls.Public
    const profilePath = `/members/${slug}`
    const url = frontendBaseUrl + profilePath
    window.open(url)
  }

  onEditProfile (id: string): void {
    this.$router.push({ name: 'Business Branch Edit', params: { id: id } })
  }

  onEditWebsite (id: string): void {
    this.$router.push({ name: 'My Website Edit', params: { id: id } })
  }
}

