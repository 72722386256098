import Vue from 'vue'
import Component from 'vue-class-component'
import { HttpError } from 'tradingmate-modules/src/services'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'
import { Prop } from 'vue-property-decorator'

// You can declare mixins as the same style as components.
@Component({
  components: {
    Form,
    FormField
  }
})
export class FormPage extends Vue {
  public loading = false
  public httpError: HttpError | null = null

  public errorHandler (key: string): string[] | null {
    return this.httpError?.ModelState?.[key] ?? null
  }

  public removeError (key: string): void {
    delete this.httpError?.ModelState?.[key]
  }

  get userError (): boolean {
    return this.httpError?.Status === 400 ?? false
  }

  // Has error that isn't a model error
  get hasError (): boolean {
    return this.httpError ? this.httpError.Status !== 400 : false
  }
}

@Component({
  components: {}
})
export class Field extends Vue {
  // Input
  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly required!: boolean

  // Field
  @Prop({ default: '' })
  private readonly label!: string

  @Prop({ default: null })
  private readonly errors!: string[] | null
}

@Component({
  components: {}
})
export class Input extends Vue {
  // Input
  @Prop({ default: '' })
  private readonly placeholder!: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly required!: boolean

  private readonly style =
  `display: block;
  width: 100%;
  height: 100%;
  min-height: 45px
  background: var(--card-bg);
  padding: var(--border-rad);
  color: var(--card-text);`
}
